	
// Import MDCRipple and MDCFormField directly
	import { MDCRipple } from '@material/ripple/index';
	import { MDCFormField } from '@material/form-field/index';
	
// To be merged or replaced by a general function
	function importStyle(id, media = '') {
		const elID = `${id}-css`;
		
	// Check if already loaded
		if( !document.getElementById(elID) ) {
			const head	= document.getElementsByTagName('head')[0];
			const link	= document.createElement('link');
			link.id		= elID;
			link.rel	= 'stylesheet';
			link.type	= 'text/css';
			link.href	= `__PUBLIC_URL__/${id}.css`;
			link.media	= media == '' ? 'all' : media;
			head.appendChild(link);
		}
		
	}
	
	window.mdc = {
		MDCRipple: MDCRipple,
		MDCFormField: MDCFormField,
		attach: (root) => {
			
		// Attach MDCRipple
			if( root.querySelector('.mdc-ripple, .mdc-button, .mdc-fab') ) {
				importStyle('button');
				root.querySelectorAll('.mdc-ripple:not([data-mdc-init="true"]), .mdc-button:not([data-mdc-init="true"]), .mdc-fab:not([data-mdc-init="true"])').forEach(el => {
					el.mdc = MDCRipple.attachTo(el);
					el.dataset.mdcInit = true;
				});
			}
			
		// Attach MDCFormField
			if( root.querySelector('.mdc-form-field') ) {
				root.querySelectorAll('.mdc-form-field:not([data-mdc-init="true"])').forEach(el => {
					el.mdc = MDCFormField.attachTo(el);
					el.dataset.mdcInit = true;
				});
			}
			
		// Attach MDCCheckbox
			if( root.querySelector('.mdc-checkbox') ) {
				importStyle('checkbox');
				import('@material/checkbox/index').then(module => {
					root.querySelectorAll('.mdc-checkbox:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCCheckbox.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCSwitch
			if( root.querySelector('.mdc-switch') ) {
				importStyle('switch');
				import('@material/switch/index').then(module => {
					root.querySelectorAll('.mdc-switch:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCSwitch.attachTo(el);
						el.dataset.mdcInit = true;
						el.addEventListener( 'click', mdcSwitchListener );
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCLinearProgress
			if( root.querySelector('.mdc-linear-progress') ) {
				importStyle('linear-progress');
				import('@material/linear-progress/index').then(module => {
					root.querySelectorAll('.mdc-linear-progress:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCLinearProgress.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCTab and MDCTabBar
			if( root.querySelector('.mdc-tab') || root.querySelector('.mdc-tab-bar') ) {
				importStyle('tab');
				import('./tabs').then(module => {
					root.querySelectorAll('.mdc-tab:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCTab.attachTo(el);
						el.dataset.mdcInit = true;
					});
					root.querySelectorAll('.mdc-tab-bar:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCTabBar.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCMenu
			if( root.querySelector('.mdc-menu') ) {
				importStyle('menu');
				import('@material/menu').then(module => {
					root.querySelectorAll('.mdc-menu:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCMenu.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCList
			if( root.querySelector('.mdc-list') ) {
				importStyle('list');
				import('@material/list').then(module => {
					root.querySelectorAll('.mdc-list:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCList.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCSnackbar
			if( root.querySelector('.mdc-snackbar') ) {
				importStyle('snackbar');
				import('@material/snackbar/index').then(module => {
					root.querySelectorAll('.mdc-snackbar:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCSnackbar.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCTooltip
			if( root.querySelector('.mdc-tooltip') ) {
				importStyle('tooltip');
				import('@material/tooltip').then(module => {
					root.querySelectorAll('.mdc-tooltip:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCTooltip.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCChip and MDCChipSet
			if( root.querySelector('.mdc-evolution-chip') || root.querySelector('.mdc-evolution-chip-set') ) {
				importStyle('chips');
				import('./chips').then(module => {
					root.querySelectorAll('.mdc-evolution-chip:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCChip.attachTo(el);
						el.dataset.mdcInit = true;
					});
					root.querySelectorAll('.mdc-evolution-chip-set:not([data-mdc-init="true"])').forEach(el => {
						el.mdc = module.MDCChipSet.attachTo(el);
						el.dataset.mdcInit = true;
					});
				}).catch(err => console.log(err));
			}
			
		// Attach MDCTooltip
			if( root.querySelector('.mdc-radio') ) {
				importStyle('radio');
			}
			
		}
	}
	
// As the MDC lib will be loaded as last, the click event will return the old value. Creating a new custom event that fires when the value has changed.
	function mdcSwitchListener(ev) {
		ev.currentTarget.value = ev.currentTarget.mdc.selected;
		let toggleSwitch = new CustomEvent('toggleSwitch', {
			detail: {
				selected: ev.currentTarget.mdc.selected,
			}
		});
		ev.currentTarget.dispatchEvent(toggleSwitch);
	}
	
// Attach MDC to all mdc-elements in the document
	window.mdc.attach(document);
	